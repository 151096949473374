<template>
  <div class="content-layout-fixer">
    <div class="top-bar">
      <transition appear appear-active-class="fade-enter-active">
        <div class="logo">
          <img src="~@/assets/logos/pmaktif-alt.png" alt="logo" />
        </div>
      </transition>
    </div>
    <div class="content-wrapper">
      <VueText sizeLevel="6" weightLevel="2" class="content-text ">
        Değerli bayimiz PMAktif'i kullanmaya internet tarayıcınız üzerinden www.pmaktif.com adresine
        girerek devam edebilirsiniz. 1 Mart 2022 tarihi itibariyle PMAktif uygulama üzerinden
        kullanıma kapanmıştır. Detaylı anlatım için videoyu izleyebilirsiniz.
      </VueText>
      <BrandVimeoPlayer
        :id="'434987200'"
        ref="vimeoPlayer"
        :data="{
          embedVideo: '434987200',
        }"
      >
      </BrandVimeoPlayer>
      <BrandButton v-if="isRedirectPmaktif" class="route-btn" @click="route" :size="sizes.xLarge"
        >ANASAYFA</BrandButton
      >
      <BrandModal />
    </div>
  </div>
</template>

<script>
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import StorageHelper from '@/utils/storageHelper';
import AccountMixin from '@/utils/accountUtils.js';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
export default {
  name: 'AppInfo',
  components: {
    BrandVimeoPlayer,
    BrandButton,
    VueText,
    BrandModal,
  },
  mixins: [AccountMixin],
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
    isRedirectPmaktif() {
      const isRedirectPmaktif = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_IS_REDIRECT_PMAKTIF,
      }).get();
      return isRedirectPmaktif;
    },
  },
  methods: {
    route() {
      let staffValidation = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_STAFF_VALIDATION,
      }).get();
      let user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();
      let posArr = new StorageHelper({ id: process.env.VUE_APP_POS_ARRAY }).get();
      if (posArr.length > 1) {
        this.$store.dispatch('auth/setPosList', posArr).then(() => {
          this.$router.push(
            `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectPos.path}`,
          );
        });
      } else {
        this.accountLogin(posArr[0].posChannel, staffValidation, user);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  padding: 20px 20px 0;
}
.content-text {
  margin-bottom: 20px;
}
.route-btn {
  margin-top: 20px;
}
.top-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../../assets/bg/bg-appbar.svg') top left no-repeat,
    linear-gradient(to left, #d20051 0%, #ffa300 100%);
  height: 70px;
  img {
    width: 105px;
  }
}
</style>
